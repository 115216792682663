import React from "react";

import LayoutGeneral from "../components/LayoutGeneral";
import Section from "../components/Section";
import SectionRow from "../components/SectionRow";
import SVGCurve from "../components/SVGCurve";
import SEO from "../components/SEO";
import TextDiv from "../components/TextDiv";
import TextParagraph from "../components/TextParagraph";
import AppointmentDiv from "../components/AppointmentDiv";
import UnorderedList from "../components/UnorderedList";
import ExternalLink from "../components/ExternalLink";
import InternalLink from "../components/InternalLink";
import PropTypes from "prop-types";

function GoodFaithEsimateNoticePage() {
    return (
        <LayoutGeneral>
            <SEO
                keywords={[`Agile Psychiatry`, `Brian Nahas MD`, `Good Faith Estimate`]}
                title="Good Faith Estimate Notice"
                description="You have the right to receive a &quot;Good Faith Estimate&quot; from Agile Psychiatry PLLC"
            />

            <Section shade="mid">
                <SectionRow>
                    <TextDiv>
                        <h2 className="text-3xl text-center mb-4">Good Faith Estimate Notice</h2>
                        <h4 className="text-2xl mb-2">You have the right to receive a &quot;Good Faith Estimate&quot; explaining
                            how much your medical care will cost.</h4>
                        <TextParagraph className="mb-8">
                            Under the law, health care providers need to give <span className="font-bold">patients who don’t have certain types of
                            health care coverage or who are not using certain types of health care coverage</span> an estimate
                            of their bill for health care items and services before those items or services are provided.
                        </TextParagraph>
                    </TextDiv>
                </SectionRow>
            </Section>
            <SVGCurve direction="right-slope" transition="mid-light"/>
            <Section shade="light">
                <SectionRow>
                    <TextDiv>
                        <UnorderedList>
                            <li className="mb-2">
                                <TextParagraph>
                                    You have the right to receive a Good Faith Estimate for the total expected cost of any
                                    health care items or services upon request or when scheduling such items or services.
                                    This includes related costs like medical tests, prescription drugs, equipment, and
                                    hospital fees.
                                </TextParagraph>
                            </li>
                            <li className="mb-2">
                                <TextParagraph>
                                    If you schedule a health care item or service at least 3 business days in advance,
                                    make sure your health care provider or facility gives you a Good Faith Estimate in
                                    writing within 1 business day after scheduling. If you schedule a health care item
                                    or service at least 10 business days in advance, make sure your health care provider
                                    or facility gives you a Good Faith Estimate in writing within 3 business days after
                                    scheduling. You can also ask any health care provider or facility for a Good Faith
                                    Estimate before you schedule an item or service. If you do, make sure the health
                                    care provider or facility gives you a Good Faith Estimate in writing within 3
                                    business days after you ask.
                                </TextParagraph>
                            </li>
                            <li className="mb-2">
                                <TextParagraph>
                                    If you receive a bill that is at least $400 more for any provider or facility than your
                                    Good Faith Estimate from that provider or facility, you can dispute the bill.
                                </TextParagraph>
                            </li>
                            <li className="mb-2">
                                <TextParagraph>
                                    Make sure to save a copy or picture of your Good Faith Estimate and the bill.
                                </TextParagraph>
                            </li>
                        </UnorderedList>
                    </TextDiv>
                </SectionRow>
            </Section>
            <SVGCurve direction="right-slope" transition="light-mid"/>
            {/*<Section shade="mid">*/}
            {/*    <SectionRow>*/}
            {/*        <TextDiv className="flex-grow">*/}
            {/*            <h2 className="text-3xl text-center mb-2">Sample Estimates for Initial Evaluations</h2>*/}
            {/*            <h3 className="text-2xl text-center mb-2">Evaluation - Low Complexity</h3>*/}
            {/*            <div className="flex flex-col lg:flex-row border-2 mb-4">*/}
            {/*                <div className="lg:w-1/2 p-3">*/}
            {/*                    <h3 className="text-2xl text-center mb-2">Example Diagnoses</h3>*/}
            {/*                    <ul className="list-disc list-inside">*/}
            {/*                        <li>Mild-moderate anxiety</li>*/}
            {/*                        <li>Mild-moderate depression</li>*/}
            {/*                        <li>Insomnia</li>*/}
            {/*                        <li>Stress</li>*/}
            {/*                        <li>Grief</li>*/}
            {/*                    </ul>*/}
            {/*                </div>*/}
            {/*                <div className="lg:w-1/2 p-3">*/}
            {/*                    <h3 className="text-2xl text-center mb-2">Billing Summary</h3>*/}
            {/*                    <LineItem><span>Initial appointment:</span><span className="align-right">$290</span></LineItem>*/}
            {/*                    <div>Additional time likely necessary:</div>*/}
            {/*                    <LineItem className="pl-2"><span>Labs/records review (10-20 min):</span><span className="align-right">$0-$64</span></LineItem>*/}
            {/*                    <LineItem className="pl-2"><span>Phone conversation (10-20 min):</span><span className="align-right">$0-$64</span></LineItem>*/}
            {/*                    <div className="font-semibold text-right">Total: $290 - $418</div>*/}
            {/*                    <div>External services not included:</div>*/}
            {/*                    <LineItem className="pl-2"><span>Lab work</span><span className="align-right">lab cost - insurance</span></LineItem>*/}
            {/*                    <LineItem className="pl-2"><span>Medication</span><span className="align-right">pharmacy cost - insurance</span></LineItem>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <h3 className="text-2xl text-center">Evaluation - Moderate Complexity</h3>*/}
            {/*            <div className="flex flex-col lg:flex-row border-2 mb-4">*/}
            {/*                <div className="lg:w-1/2 p-3">*/}
            {/*                    <h3 className="text-2xl text-center mb-2">Example Diagnoses</h3>*/}
            {/*                    <ul className="list-disc list-inside">*/}
            {/*                        <li>new/clear ADHD diagnosis*/}
            {/*                            <ul className="list-disc disc-inside ml-8">*/}
            {/*                            <li className="italic">ADHD diagnosis may require additional external testing</li>*/}
            {/*                            </ul>*/}
            {/*                        </li>*/}
            {/*                        <li>Contributing medical issues</li>*/}
            {/*                        <li>Inaccurate prior diagnosis</li>*/}
            {/*                        <li>Prior treatment that didn&apos;t work</li>*/}
            {/*                    </ul>*/}
            {/*                </div>*/}
            {/*                <div className="lg:w-1/2 p-3">*/}
            {/*                    <h3 className="text-2xl text-center mb-2">Billing Summary</h3>*/}
            {/*                    <LineItem><span>Initial appointment:</span><span className="align-right">$290</span></LineItem>*/}
            {/*                    <div>Additional time likely necessary:</div>*/}
            {/*                    <LineItem className="pl-2"><span>Labs/records reviews (10-30 min):</span><span className="align-right">$0-$128</span></LineItem>*/}
            {/*                    <LineItem className="pl-2"><span>Conversation w/ family (20-60 min):</span><span className="align-right">$64-$320</span></LineItem>*/}
            {/*                    <div className="italic">*/}
            {/*                    <div>Additional time possibly necessary:</div>*/}
            {/*                    <LineItem className="pl-2"><span>Conversation w/ other provider (10-30 min):</span><span className="align-right">$0-$128</span></LineItem>*/}
            {/*                    </div>*/}
            {/*                    <div className="font-semibold text-right">Total: $354 - $866</div>*/}
            {/*                    <div>External services not included:</div>*/}
            {/*                    <LineItem className="pl-2"><span>Neuropsychological Testing</span><span className="align-right">test cost - insurance</span></LineItem>*/}
            {/*                    <LineItem className="pl-2"><span>Lab work</span><span className="align-right">lab cost - insurance</span></LineItem>*/}
            {/*                    <LineItem className="pl-2"><span>Medication</span><span className="align-right">pharmacy cost - insurance</span></LineItem>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <h3 className="text-2xl text-center">Evaluation - High Complexity</h3>*/}
            {/*            <div className="flex flex-col lg:flex-row border-2">*/}
            {/*                <div className="lg:w-1/2 p-3">*/}
            {/*                    <h3 className="text-2xl text-center mb-2">Example Diagnoses</h3>*/}
            {/*                    <ul className="list-disc list-inside">*/}
            {/*                        <li>Unclear ADHD diagnosis*/}
            {/*                            <ul className="list-disc disc-inside ml-8">*/}
            {/*                                <li className="italic">ADHD diagnosis may require additional external testing</li>*/}
            {/*                            </ul>*/}
            {/*                        </li>*/}
            {/*                        <li>Complex medical issues</li>*/}
            {/*                        <li>Serious mental illness*/}
            {/*                            <ul className="list-disc disc-inside ml-8">*/}
            {/*                                <li className="italic">History of hospitalization</li>*/}
            {/*                                <li className="italic">History of suicide attempt(s)</li>*/}
            {/*                            </ul>*/}
            {/*                        </li>*/}
            {/*                        <li>Lengthy treatment history</li>*/}
            {/*                        <li>Multiple diagnoses</li>*/}
            {/*                    </ul>*/}
            {/*                </div>*/}
            {/*                <div className="lg:w-1/2 p-3">*/}
            {/*                    <h3 className="text-2xl text-center mb-2">Billing Summary</h3>*/}
            {/*                    <LineItem><span>Initial diagnostic appointment:</span><span className="align-right">$290</span></LineItem>*/}
            {/*                    <LineItem><span>Follow-up appointment to start treatment:</span><span className="align-right">$165-$230</span></LineItem>*/}
            {/*                    <div>Additional time likely necessary:</div>*/}
            {/*                    <LineItem className="pl-2"><span>Labs/records reviews (30-60 min):</span><span className="align-right">$128-$320</span></LineItem>*/}
            {/*                    <LineItem className="pl-2"><span>Conversation w/ family (30-60 min):</span><span className="align-right">$128-$320</span></LineItem>*/}
            {/*                    <LineItem className="pl-2"><span>Conversation w/ other provider (10-30 min):</span><span className="align-right">$0-$128</span></LineItem>*/}
            {/*                    <div className="font-semibold text-right">Total: $711 - $1288</div>*/}
            {/*                    <div>External services not included:</div>*/}
            {/*                    <LineItem className="pl-2"><span>Neuropsychological Testing</span><span className="align-right">test cost - insurance</span></LineItem>*/}
            {/*                    <LineItem className="pl-2"><span>Lab work</span><span className="align-right">lab cost - insurance</span></LineItem>*/}
            {/*                    <LineItem className="pl-2"><span>Medication</span><span className="align-right">pharmacy cost - insurance</span></LineItem>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </TextDiv>*/}
            {/*    </SectionRow>*/}
            {/*</Section>*/}
            {/*<SVGCurve direction="right-slope" transition="mid-light"/>*/}
            <Section shade="mid">
                <SectionRow>
                    <TextDiv>
                        <TextParagraph className="mb-8">
                            To request a formal Good Faith Estimate for your specific situation, submit an <InternalLink to="appointments">appointment request</InternalLink>,
                            <InternalLink to="contact">call our office</InternalLink>, or submit a request below.
                        </TextParagraph>
                        <TextParagraph className="mb-8">
                            For questions or more information about your right to a Good Faith Estimate, visit <ExternalLink to="https://www.cms.gov/nosurprises/consumers">https://www.cms.gov/nosurprises/consumers</ExternalLink>,
                            email <ExternalLink to="mailto:FederalPPDRQuestions@cms.hhs.gov">FederalPPDRQuestions@cms.hhs.gov</ExternalLink>,
                            or call <a href="tel:8009853059">1-800-985-3059</a>.
                        </TextParagraph>
                    </TextDiv>
                </SectionRow>
            </Section>
            <SVGCurve direction="right-slope" transition="mid-light"/>
            <Section shade="light">
                <SectionRow>
                    <iframe
                        src="https://docs.google.com/forms/d/e/1FAIpQLSdSnLRh8lnx8qniXFU8YmLMNZN2qNVGLcKavvJKDhMmfJ7bUw/viewform?embedded=true"
                        width="100%" height="2100" frameBorder="0" marginHeight="0" marginWidth="0">Loading…
                    </iframe>
                </SectionRow>
            </Section>
            <SVGCurve direction="right-slope" transition="light-dark"/>
            <Section shade="dark">
                <SectionRow>
                    <AppointmentDiv/>
                </SectionRow>
            </Section>
            {/*<SVGCurve direction="right-slope" color="text-ap-dark-bg" bgColor="bg-gray-200"/>*/}
        </LayoutGeneral>
    );
}

const LineItem = (props) => {
    return (
        <div className={"flex justify-between " + (props.className ?? "")}>
            {props.children}
        </div>
    )
}

LineItem.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string
}

export default GoodFaithEsimateNoticePage;
